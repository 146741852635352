import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import GetContents from "../components/getContents"
import GetMenu from "../components/getMenu"
import Footer from "../components/footer"
import Metatags from "../components/metatags"
import "../css/multilogica-theme.scss"

import LogoWhite from "../assets/MultilogicaShop_8_slogan_branco.svg"

const Tutoriais = ({ data }) => {
  const topRef = useRef()

  const [tutorialFilter, setTutorialFilter] = useState("Arduino")
  const [contents, setContents] = useState({})


  useEffect(() => {
    const setContent = ctt => {
      let contentAux = []
      data.gatsbyappsync.listTutoriaisMultilogicas.items.forEach(element => {
        if (element.classification.includes(tutorialFilter)) {
          contentAux.push(element)
        }
      })
      setContents(contentAux)
      setTutorialFilter(ctt)
    }
    const category = sessionStorage.getItem("tutorialCategory")
    if (category !== null) {
      setContent(category)
    } else {
      setContent("Arduino")
    }
  }, [tutorialFilter, data.gatsbyappsync.listTutoriaisMultilogicas.items])

  const setTutorial = tutorialCategory => {
    setTutorialFilter(tutorialCategory)
    sessionStorage.setItem("tutorialCategory", tutorialCategory)
  }

  return (
    <>
      <Metatags
        title="Multilogica | Tutoriais"
        description="Tutoriais da Multilógica-shop"
        url="https://tutoriais.multilogica-shop.com"
        image="https://tutoriais.multilogica-shop.com/Multilogica-shop.png"
        keywords="eletrônica, arduino, micro:bit, raspberry pi, sparkfun, pololu"
      />
      <Container fluid style={{ padding: "0" }} ref={topRef}>
        <Row style={{ maxWidth: "100%", margin: 0 }}>
          <Col className="home" style={{ padding: 0 }}>
            <div style={{ position: "relative" }}>
              <GatsbyImage
                image={getImage(data.tutorialImage)}
                backgroundColor={false}
                alt="Tutoriais Multilógica"
                style={{
                  left: 0,
                  top: 0,
                }}
                className="bg-image"
              />
              <div className="header-text">
                <div style={{ width: "100%", textAlign: "center" }}>
                  <LogoWhite id="logo-multilogica" />

                  <h1>Tutoriais</h1>

                  <a
                    href="https://multilogica-shop.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="transp-button">
                      Voltar para a loja &gt;
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="height-40" />
      <Container>
        <Row>
          <Col sm={2}>
            <GetMenu
              setTutorialFilter={setTutorial}
              active={tutorialFilter}
              contents={data.gatsbyappsync.listTutoriaisMultilogicas}
            />
          </Col>
          <Col sm={10}>
            <Container>
              <Row>
                <Col>
                  <Container>
                    <GetContents type="tutorial" contents={contents} />
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <div className="height-40" />
      <Footer />
    </>
  )
}

export default Tutoriais

export const query = graphql`
  query tutorialData {
    gatsbyappsync {
      listTutoriaisMultilogicas(limit: 1000) {
        items {
          publicationDate
          picture
          title
          teaser
          slug
          id
          classification
        }
      }
    }

    tutorialImage: file(relativePath: { eq: "Tutorial_Testeira_1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
