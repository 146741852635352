import React, { useState, useEffect } from "react"
import { Button, Row } from "react-bootstrap"

function GetContents(props) {
  const [menuContent, setMenuContent] = useState()

  useEffect(() => {
    function createMenu(data) {
      return data.map((menuEntry, k) => {
        return (
          <Button 
            key={k} 
            className={menuEntry === props.active ? "menu-button active" : "menu-button"}
            size="sm"
            onClick={e => props.setTutorialFilter(menuEntry)}
            >
            {menuEntry}
          </Button>
        )
      })
    }

    var arrayData = []
    for (var i in props.contents.items) {
      props.contents.items[i]["classification"]
        .replaceAll('"', "")
        .replace("[", "")
        .replace("]", "")
        .split(",")
        .forEach(element => {
          if (!arrayData.includes(element) && element !== "") {
            arrayData.push(element)
          }
        })
    }

    arrayData.sort((a, b) => {
      const r =
        a.toLowerCase() < b.toLowerCase()
          ? -1
          : b.toLowerCase() < a.toLowerCase()
          ? 1
          : 0
      return r
    })
    setMenuContent(createMenu(arrayData))
  }, [props])

  return <Row className="row-same-size-columns" style={{alignItems:"center", justifyContent:"center"}}>{menuContent}</Row>
}

export default GetContents
