import React, { useState, useEffect } from "react"
import sanitizeHtml from "sanitize-html"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { ImgixGatsbyImage } from "@imgix/gatsby"

function GetContents(props) {

  const [tutorialContent, setTutorialContent] = useState()

  useEffect(() => {
    function createContent(data) {
      return data.map((tutorialEntry, k) => {
        if (tutorialEntry.picture === ''){
          tutorialEntry['picture'] = 'https://cdn.multilogica-shop.com/blog/Blog_Generico_MultilogicaShop.png'
        }
        return (
          <Col md={4} className="same-size-column-4" key={k}>
            <div className="content-conteiner">
              <ImgixGatsbyImage
                src={sanitizeHtml(tutorialEntry.picture)}
                layout="constrained"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Marketing de Relacionamento"
                aspectRatio={1.48}
              />
              <div className="white-bottom-content">
                <h5 className="text-bold">{sanitizeHtml(tutorialEntry.title)}</h5>
                <div dangerouslySetInnerHTML={{__html:tutorialEntry.teaser}} />
                <Link to={`/${props.type}/${tutorialEntry.slug}`}>
                  <button className="text-blue-button">Leia mais</button>
                </Link>
              </div>
            </div>
          </Col>
        )
      })
    }

    var arrayData = []
    for (var i in props.contents) {
      arrayData.push(props.contents[i])
    }
    arrayData.sort((a, b) =>
      a["publicationDate"] < b["publicationDate"]
        ? 1
        : b["publicationDate"] < a["publicationDate"]
        ? -1
        : 0
    )

    setTutorialContent(createContent(arrayData))
  }, [props])

  return <Row className="row-same-size-columns">{tutorialContent}</Row>
}

export default GetContents
